<template>
    <div class="order-info" style="overflow: auto">
        <div class="head" style="width: 1500px; min-height: 170px;background: #FFFFFF; margin: 10px auto;">
            <ul style="display: flex;padding: 0 40px">
                <li v-for="(item,index) in flowList" :key="index" class="info">
                    <!--  圆圈节点  -->
                    <div :class="{active: index  < serviceInfo.status}"  class="primary"><i :class="item.icon"></i></div>
                    <!--  线   -->
                    <div :class="{active: index  < serviceInfo.status - 1}" class="error" v-if="index <= 3"></div>
                    <!--  标注    -->
                    <div class="dark">{{ item.title }}</div>
                </li>
            </ul>
        </div>
        <div v-if="user.uuid === serviceInfo.uuid" style="width: 1500px; min-height: 600px;background: #FFFFFF; margin: 0 auto">
            <ServiceTable v-if="serviceInfo.status === 1" ref="serviceTable" :serviceInfo="serviceInfo.demandInfo"></ServiceTable>
            <ServiceOrder v-if="serviceInfo.status > 1" :type="1" :serviceInfo="serviceInfo"></ServiceOrder>

            <div style="font-size: 16px; margin: 20px" v-if="serviceInfo.status === 1">
                <div style="font-weight: 600; color: #EE3F4D; margin-bottom: 10px">需求修改建议：</div>
                <div style="margin: 0 0 20px 20px" v-for="(item, index) in serviceInfo.suggest" :key="index">{{item.time}}：{{item.content}}</div>
            </div>
            <div style="margin: 30px 0; padding-bottom: 40px; display: flex; justify-content: end">
                <div @click="cancelOrder" class="foot-btn hand op" style="color: #999999; border: 1px #CCCCCC solid" v-if="serviceInfo.uuid === user.uuid && serviceInfo.status < 4">撤销订单</div>
                <div v-if="serviceInfo.status === 1" class="foot-btn hand op" style="color: #2970FF; border: 1px #2970FF solid" @click="submitOrderInfo">提交订单信息</div>
                <div v-if="serviceInfo.status === 2" class="foot-btn" style="background-color: #2970FF; color: #FFFFFF">已提交订单信息</div>
            </div>
        </div>

        <ServiceOrder :serviceInfo="serviceInfo" :type="2" v-if="user.uuid !== serviceInfo.uuid"></ServiceOrder>
    </div>
</template>

<script>

import ServiceOrder from "../components/ServiceOrder";
import ServiceTable from "../components/ServiceTable";
export default {
    name: "serviceVerify",
    components: {ServiceTable, ServiceOrder},
    data() {
        return {
            demandInfo: {},

            show: 0,
            flowList: [
                {
                    status: 1,
                    title: '填写服务需求',
                    icon: 'el-icon-position'
                },
                {
                    status: 2,
                    title: '查看处理需求',
                    icon: 'el-icon-check'
                },
                {
                    status: 3,
                    title: '填写付款方式',
                    icon: 'el-icon-check'
                },
                {
                    status: 4,
                    title: '确认付款方式',
                    icon: 'el-icon-check'
                },
                {
                    status: 5,
                    title: '生成服务订单',
                    icon: 'el-icon-s-check'
                }
            ],

            serviceInfo: {},

            checked: false,
            input: '',
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created() {

    },
    mounted() {
        this.getServiceDetail()
    },
    methods: {
        // 撤销订单
        cancelOrder() {
            this.utils.confirm('撤销后此需求订单将不存在，确定撤销订单吗？', () => {
                this.newApi.cancelDemand({id: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.utils.a('/shop/order/')
                    }
                })
            })
        },

        // 提交订单信息
        submitOrderInfo() {
            let serviceInfo = this.$refs.serviceTable.submitInfo()
            this.newApi.procurementServices({demandId: this.$route.params.id, serviceId: this.serviceInfo.serviceId, demandInfo: JSON.stringify(serviceInfo), roomId: '', sequenceId: ''}).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.getServiceDetail()
                }
            })
        },

        // 获取服务详情
        getServiceDetail() {
            this.newApi.getShopDemandInfo({demandId: this.$route.params.id}).then(res => {
                if (res.isSuccess === 1) {
                    this.serviceInfo = res.data;
                    this.show = 2;
                }
            })
        },
    }
}
</script>

<style scoped>
.order-info {
    height: 100vh;
    overflow: hidden;
    background:#f0f0f0;
}

.dark {
    margin-top: 10px;
    color: #444444;
    font-size: 18px;
}

.info {
    display: inline-block;
    width: 320px;
    padding-top: 40px;
}

.primary {
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    color: #031F88;
    background: #E8F2FB;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: auto 30px;
    line-height: 38px;
}
.primary.active {
    background-color: #FFFFFF !important;
    border: 3px #031F88 solid;
    border-radius: 50%;
}

.error.active{
    border-top: 10px solid #031F88;
}

.error {
    width: 300px;
    margin-left: 70px;
    margin-top: -22px;
    height: 10px;
    border-top: 10px solid #E8F2FB;
}

.order-info .foot-btn {
    padding: 10px 36px;
    font-size: 16px;
    margin: 0 20px;
    font-weight: 600;
    border-radius: 5px;
}
</style>