<template>
    <div class="service-order">
        <div class="body-box" style="width: 1440px; margin-right: 10px; margin-bottom: 10px">
            <div class="body-title">服务需求订单</div>
            <div style="border: 1px #BABABA solid;" v-if="serviceInfo.demandInfo">
                <table>
                    <tr style="width: 1440px;">
                        <th class="title" style="width: 144px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务名称</div></th>
                        <td class="bold" style="width: 420px; font-size: 18px">
                            <div style="text-align: center">{{serviceInfo.demandInfo.title}}</div>
                        </td>
                        <th class="title" style="width: 144px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">需求名称</div></th>
                        <td class="bold" style="border-right: none; font-size: 18px">
                            <div style="text-align: center">{{serviceInfo.demandInfo.name}}</div>
                        </td>
                    </tr>
                    <tr style="width: 1440px;">
                        <th class="title" style="width: 144px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">价格范围</div></th>
                        <td class="bold" style="width: 420px; font-size: 18px">
                            <div style="text-align: center; color: #EE3F4D" v-if="serviceInfo.demandInfo.maxPrice === 0">￥{{serviceInfo.demandInfo.minPrice}}</div>
                            <div style="text-align: center; color: #EE3F4D" v-else>￥{{serviceInfo.demandInfo.minPrice }} - {{serviceInfo.demandInfo.maxPrice}}</div>
                        </td>
                        <th class="title" style="width: 144px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">需求金额</div></th>
                        <td class="bold" style="border-right: none; font-size: 18px">
                            <div style="text-align: center; color: #EE3F4D" v-if="serviceInfo.demandInfo.price">￥{{serviceInfo.demandInfo.price}}</div>
                        </td>
                    </tr>
                    <tr style="width: 1440px">
                        <th class="title" style="width: 144px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务介绍</div></th>
                        <td class="bold" style="width: 420px;">
                            <div style="height: 600px;width: 420px; overflow: auto" v-html="serviceInfo.demandInfo.content"></div>
                        </td>
                        <th class="title" style="width: 144px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">需求内容</div></th>
                        <td class="bold bold-edit" style="width: 710px; border-right: none;">
                            <div style="height: 600px;width: 710px; overflow: auto" v-html="serviceInfo.demandInfo.demand"></div>
                        </td>
                    </tr>
                </table>
                <table style="width: 1440px">
                    <tr v-for="(item,index) in serviceInfo.demandInfo.otherSpecifications" :key="index" style="width: 1440px">
                        <div v-if="item.type !== 6">
                            <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">{{item.key}}</div></th>

                            <td class="bold" style="width: 430px" v-if="item.type==1 || item.type==2">
                                <div style="text-align: center">{{item.value}}</div>
                            </td>

                            <td class="bold" style="width: 430px" v-if="item.type==3 || item.type == 5">
                                <div style="text-align: center" v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
                                    <div v-if="item.radio === selectItem.radio">{{selectItem.name}}</div>
                                </div>
                            </td>

                            <td class="bold" style="width: 430px" v-if="item.type==4">
                                <div style="text-align: center">
                                    <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
                                        <div v-if="item.data.includes(selectItem.radio)">{{selectItem.name}}</div>
                                    </div>
                                </div>
                            </td>

                            <td class="bold" style="width: 150px; font-size: 14px">
                                <div class="select-box">
                                    <div v-if="item.type === 1 || item.type === 2">
                                        <div v-if="item.status === 0">已选</div>
                                        <div v-if="item.status === 1">未选</div>
                                    </div>
                                    <div v-else>备注</div>
                                </div>
                            </td>

                            <td class="bold-content" style="width: 732px; border-right: none">
                                <div>
                                    {{item.content}}
                                </div>
                            </td>
                        </div>

                        <div v-else>
                            <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">{{item.key}}</div></th>
                            <td class="bold" style="width: 1335px">
                                <div style="width: calc(100% - 2px);border:1px solid #BABABA;">
                                    <div style="width: 100%;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" :style="pindex==item.value.length-1?'':'border-bottom: 1px solid #BABABA'">
                                        <div v-if="p.radio==item.data">
                                            <div style="width: 14%; overflow: hidden; float: left;">
                                                <div style="height: auto; line-height: 40px; text-align: center">{{ p.name }}</div>
                                            </div>
                                            <div style="width: calc(86% - 2px);overflow:hidden;border-left: 1px solid #BABABA">
                                                <div style="overflow:hidden; float: left; width:35%; border-right: 1px solid #BABABA">
                                                    <div v-for="(k,kindex) in p.value" :key="kindex" style="line-height: 40px;height:auto;text-align: center;" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid #BABABA'">{{ k }}</div>
                                                </div>
                                                <div style="width: calc(65% - 12px);overflow:hidden; padding-left: 10px; padding-top: 10px">
                                                    {{p.content}}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </td>
                        </div>
                    </tr>

                </table>
                <table>
                    <tr style="width: 1440px">
                        <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务输入条件</div></th>
                        <td class="bold-content" style="width: 433px">
                            <div v-if="serviceInfo.demandInfo.input">
                                <div v-for="(item, index) in serviceInfo.demandInfo.input.slice(0, serviceInfo.demandInfo.input.length - 1)" :key="index">{{index + 1}}.{{item}}</div>
                            </div>
                        </td>
                        <td class="bold" style="width: 150px">
                            <div class="select-box">
                                <div v-if="serviceInfo.demandInfo.input" style="font-size: 14px">
                                    <div v-if="serviceInfo.demandInfo.input[serviceInfo.demandInfo.input.length - 1].status === 0">已选</div>
                                    <div v-if="serviceInfo.demandInfo.input[serviceInfo.demandInfo.input.length - 1].status === 1">未选</div>
                                </div>
                            </div>
                        </td>

                        <td class="bold-content" style="width: 732px; border-right: none">
                            <div v-if="serviceInfo.demandInfo.input">
                                {{serviceInfo.demandInfo.input[serviceInfo.demandInfo.input.length - 1].content}}
                            </div>
                        </td>
                    </tr>
                    <tr style="width: 1440px">
                        <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务输出形式</div></th>
                        <td class="bold-content" style="width: 433px">
                            <div v-if="serviceInfo.demandInfo.output">
                                <div v-for="(item, index) in serviceInfo.demandInfo.output.slice(0, serviceInfo.demandInfo.output.length - 1)" :key="index">{{index + 1}}.{{item}}</div>
                            </div>
                        </td>
                        <td class="bold" style="width: 150px">
                            <div class="select-box">
                                <div v-if="serviceInfo.demandInfo.output" style="font-size: 14px">
                                    <div v-if="serviceInfo.demandInfo.output[serviceInfo.demandInfo.output.length - 1].status === 0">已选</div>
                                    <div v-if="serviceInfo.demandInfo.output[serviceInfo.demandInfo.output.length - 1].status === 1">未选</div>
                                </div>
                            </div>
                        </td>

                        <td class="bold-content" style="width: 732px; border-right: none">
                            <div v-if="serviceInfo.demandInfo.output">
                                {{serviceInfo.demandInfo.output[serviceInfo.demandInfo.output.length - 1].content}}
                            </div>
                        </td>
                    </tr>
                    <tr style="width: 1440px">
                        <th class="title" style="width: 150px; border-bottom: none; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务达到的标准</div></th>
                        <td class="bold-content" style="width: 433px; border-bottom: none">
                            <div v-if="serviceInfo.demandInfo.standard">
                                <div v-for="(item, index) in serviceInfo.demandInfo.standard.slice(0, serviceInfo.demandInfo.standard.length - 1)" :key="index">{{index + 1}}.{{item}}</div>
                            </div>
                        </td>
                        <td class="bold" style="width: 150px; border-bottom: none">
                            <div class="select-box" style="font-size: 14px">
                                <div v-if="serviceInfo.demandInfo.standard">
                                    <div v-if="serviceInfo.demandInfo.standard[serviceInfo.demandInfo.standard.length - 1].status === 0">已选</div>
                                    <div v-if="serviceInfo.demandInfo.standard[serviceInfo.demandInfo.standard.length - 1].status === 1">未选</div>
                                </div>
                            </div>
                        </td>
                        <td class="bold-content" style="width: 732px; border-right: none; border-bottom: none">
                            <div v-if="serviceInfo.demandInfo.standard">
                                {{serviceInfo.demandInfo.standard[serviceInfo.demandInfo.standard.length - 1].content}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="serviceInfo.status === 2 && user.uuid !== serviceInfo.uuid" style="margin: 30px 0; display: flex; justify-content: end">
                <div @click="showContent = true" class="foot-btn hand op" style="color: #FF0000; border: 1px #FF0000 solid">拒绝服务订单</div>
                <div @click="orderOption(1)" class="foot-btn hand op" style="color: #67C23A; border: 1px #67C23A solid">同意服务订单</div>
            </div>
            <div v-if="serviceInfo.status === 1 && user.uuid !== serviceInfo.uuid" style="margin: 30px 0; display: flex; justify-content: end">
                <div class="foot-btn hand op" style="color: #999999; border: 1px #999999 solid">已拒绝</div>
            </div>
        </div>

        <div style="display: flex">
            <div class="body-box" style="width: 680px; margin-right: 10px" v-if="serviceInfo.paySuggest && serviceInfo.paySuggest.length > 0">
                <div>
                    <div class="body-title">付款方式修改建议</div>
                    <div style="font-size: 16px;">
                        <div v-for="(item, index) in serviceInfo.paySuggest" :key="index" style="margin-bottom: 20px; line-height:30px; margin-left: 10px">
                            {{item.time}}：{{item.content}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-box" style="width: 670px" v-if="serviceInfo.status === 3">
                <div class="body-title">付款方式</div>

                <div style="min-height: 300px; border: 1px #BABABA solid; text-align: center;"  v-if="user.uuid === serviceInfo.uuid">
                    <div style="font-weight: 600; font-size: 18px; line-height: 300px">等待服务方上传付款方式 ...</div>
                </div>
                <div v-if="user.uuid !== serviceInfo.uuid">
                    <div style="border: 1px #BABABA solid;">
                        <table>
                            <tr>
                                <th class="title" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务总金额</div></th>
                                <td class="bold-right" style="width: 650px">
                                    <el-input type="number" min="0" v-model="payInfo.price">
                                    </el-input>
                                </td>
                            </tr>
                            <tr>
                                <th class="title" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">支付次数</div></th>
                                <td class="bold-right" style="width: 650px; text-align: center">
                                    <el-radio-group v-model="payInfo.payNum" @input="checkoutNum">
                                        <el-radio v-for="(selectItem, selectIndex) in number" :key="selectIndex" :label="selectItem">{{selectItem}}次</el-radio>
                                    </el-radio-group>
                                </td>
                            </tr>
                        </table>
                        <table v-for="(item, index) in payInfo.payInfo" :key="index">
                            <tr>
                                <th class="title" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">第{{index + 1}}次支付</div></th>
                                <td class="bold-right" style="width: 650px">
                                    <el-input type="number" min="0" v-model="item.price">
                                    </el-input>
                                </td>
                            </tr>
                            <tr>
                                <th class="title" :style="payInfo.payInfo.length - 1 === index ? 'border-bottom: none' : ''" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">支付条件</div></th>
                                <td class="bold-right" style="width: 650px;" :style="payInfo.payInfo.length - 1 === index ? 'border-bottom: none' : ''">
                                    <el-input
                                        type="textarea"
                                        v-model="item.content"
                                        :autosize="true"
                                        clearable>
                                    </el-input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style="margin: 30px 0; display: flex; justify-content: end">
                        <!--                    <div class="foot-btn hand op" style="color: #999999; border: 1px #CCCCCC solid">修改付款方式</div>-->
                        <div @click="SubmitPaymentMethod" class="foot-btn hand op" style="color: #2970FF; border: 1px #2970FF solid; margin-right: 0">提交付款方式</div>
                    </div>
                </div>

            </div>
            <div class="body-box" style="width: 670px" v-if="serviceInfo.status >= 4">
                <div class="body-title">付款方式</div>
                <div>
                    <div style="border: 1px #BABABA solid;">
                        <table>
                            <tr>
                                <th class="title" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务总金额</div></th>
                                <td class="bold-right" style="width: 650px">
                                    <div style="text-align: center; color: #EE3F4D">￥{{serviceInfo.payInfo.price}}</div>
                                </td>
                            </tr>
                            <tr>
                                <th class="title" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">支付次数</div></th>
                                <td class="bold-right" style="width: 650px; text-align: center">
                                    <div style="text-align: center;">{{serviceInfo.payInfo.payNum}}</div>
                                </td>
                            </tr>
                        </table>
                        <table v-for="(item, index) in serviceInfo.payInfo.payInfo" :key="index">
                            <tr>
                                <th class="title" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">第{{index + 1}}支付</div></th>
                                <td class="bold-right" style="width: 650px">
                                    <div style="text-align: center; color: #EE3F4D">￥{{item.price}}</div>
                                </td>
                            </tr>
                            <tr>
                                <th class="title" :style="serviceInfo.payInfo.payInfo.length - 1 === index ? 'border-bottom: none' : ''" style="width: 160px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">支付条件</div></th>
                                <td class="bold-right" style="width: 650px;" :style="serviceInfo.payInfo.payInfo.length - 1 === index ? 'border-bottom: none' : ''">
                                    <div style="text-align: center; line-height: 30px">{{item.content}}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="serviceInfo.status === 4">
                        <div style="margin: 30px 0; display: flex; justify-content: end"  v-if="user.uuid !== serviceInfo.uuid">
                            <!--                    <div class="foot-btn hand op" style="color: #999999; border: 1px #CCCCCC solid">修改付款方式</div>-->
                            <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF; margin-right: 0">已提交付款方式</div>
                        </div>

                        <div style="font-size: 16px; margin: 20px" v-if="user.uuid === serviceInfo.uuid">
                            <span style="font-weight: 600; color: #EE3F4D">* 注意：</span>
                            <span>请确认订单内容及付款方式，同意后将生成订单并无法修改</span>
                        </div>
                        <div style="margin: 30px 0; display: flex; justify-content: end"  v-if="user.uuid === serviceInfo.uuid">
                            <div @click="cancelOrder" class="foot-btn hand op" style="color: #999999; border: 1px #CCCCCC solid" v-if="serviceInfo.uuid === user.uuid">撤销订单</div>
                            <div @click="showContent = true" class="foot-btn hand op" style="color: #FF0000; border: 1px #FF0000 solid">拒绝付款方式</div>
                            <div @click="handlePaymentMethod(1)" class="foot-btn hand op" style="color: #67C23A; border: 1px #67C23A solid; margin-right: 0">同意付款方式</div>
                        </div>
                    </div>
                    <div v-if="serviceInfo.status === 5">
                        <div style="margin: 30px 0; display: flex; justify-content: end">
                            <div class="foot-btn hand op" style="background-color: #67C23A; color: #FFFFFF;">已生成订单</div>
                            <div class="foot-btn hand op" @click="toServiceInfo" style="background-color: #2970FF; color: #FFFFFF; margin-right: 0">查看订单 ></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <!--拒绝修改建议弹窗-->
        <el-dialog :close-on-click-modal="false" :visible.sync="showContent" width="44%" :show-close="false" class="event-form">
            <div class="top">
                修改建议
                <img src="../../../assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showContent = false">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <el-input
                    type="textarea"
                    v-model="refuseContent"
                    :rows="8">
                </el-input>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                    <div class="hand op" @click="showContent = false" style="border: 1px solid #DDDDDD;font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">取消</div>
                    <div class="hand op" v-if="user.uuid !== serviceInfo.uuid" @click="orderOption(0)" style="background-color: #2970FF; color: #FFFFFF; font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">确认</div>
                    <div class="hand op" v-if="user.uuid === serviceInfo.uuid" @click="handlePaymentMethod(0)" style="background-color: #2970FF; color: #FFFFFF; font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">确认</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "ServiceOrder",
    components: {},
    data() {
        return {
            showContent: false,
            refuseContent: '',
            number: 4,
            payInfo: {
                price: '',
                payNum: 1,
                payInfo: []
            },
        }
    },
    props: {
        serviceInfo: {
            type: Object,
            default() {
                return {
                }
            }
        },

        type: {
            type: Number,  // 1.委托方查看，2.服务方查看，3.服务方同意需求后查看
            default() {
                return 0;
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    watch: {
        serviceInfo: {
            handler: function () {
                if (this.serviceInfo.payInfo) {
                    this.payInfo = this.serviceInfo.payInfo
                } else {
                    this.checkoutNum(this.payInfo.payNum)
                }
            },
            deep: true
        }
    },
    created: function () {
    },
    mounted: function () {
    },
    methods: {
        // 撤销订单
        cancelOrder() {
            this.utils.confirm('撤销后此需求订单将不存在，确定撤销订单吗？', () => {
                this.newApi.cancelDemand({id: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.utils.a('/shop/order/')
                    }
                })
            })
        },

        // 查看订单详情
        toServiceInfo() {
            if (this.user.uuid === this.serviceInfo.uuid) {
                this.utils.a('/shop/order/')
            } else {
                this.utils.a('/shop/shopBackstage/')
            }

        },

        // 委托方处理付款方式
        handlePaymentMethod(status) {
            if (status === 0) {
                if (!this.refuseContent) {
                    this.utils.err('请填写拒绝原因及修改建议')
                    return false
                }
            }
            var param = {};
            param.status = status;
            param.content = this.refuseContent;
            param.demandId = this.serviceInfo.id;
            this.newApi.agreeOrRefusePayInfo(param).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$parent.getServiceDetail()
                    this.showContent = false;
                    this.refuseContent = '';
                }
            })
        },

        // 服务方提交支付方式
        SubmitPaymentMethod() {
            var param = {};
            var num = 0
            for (const item of this.payInfo.payInfo) {
                num = num + Number(item.price)
            }
            num = num.toFixed(2)
            if (num !== Number(this.payInfo.price).toFixed(2)) {
                this.utils.err('支付金额与总金额不符，请重新填写')
                return
            }
            if (this.payInfo.price) {
                for (const item of this.payInfo.payInfo) {
                    if (!item.price || !item.content) {
                        this.utils.err('请完善支付信息')
                        return
                    }
                }
            }
            param = {
                demandId: this.serviceInfo.id,
                payInfo: JSON.stringify(this.payInfo.payInfo),
                payNum: this.payInfo.payNum,
                price: this.payInfo.price,
            }
            this.newApi.fillPriceAndPaymentInformation(param).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$parent.getServiceDetail()
                }
            })
        },

        // 支付次数改变
        checkoutNum(num) {
            this.payInfo.payInfo = [];
            this.payInfo.payNum = num;
            var arr = [];
            for(let i = 0; i<num; i++){
                arr.push(i);
            }
            for (let i = 0; arr.length > i; i++) {
                this.payInfo.payInfo.push({price: '', content: '', isCompleted:0})
            }
        },

        // 订单同意或拒绝操作
        orderOption(status) {
            if (status === 0) {
                if (!this.refuseContent) {
                    this.utils.err('请填写拒绝原因及修改建议')
                    return false
                }
            }
            var param = {};
            param.status = status;
            param.content = this.refuseContent;
            param.demandId = this.serviceInfo.id;
            this.newApi.refusalOrConsentProcurement(param).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$parent.getServiceDetail()
                    this.showContent = false;
                    this.refuseContent = ''
                }
            })
        },
    }
}
</script>

<style scoped>
    .service-order {
        width: 1500px;
        margin: 10px auto;
    }

    .service-order .body-box {
        background: #FFFFFF;
        padding: 20px 30px;
    }

    .service-order .body-title {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }

    .service-order .bold {
        display: table-cell;
        vertical-align: middle;
        font-size: 16px;
        padding: 10px 0;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-order .bold-right {
        display: table-cell;
        vertical-align: middle;
        font-size: 16px;
        padding: 10px 0;
        border-bottom: 1px #BABABA solid;
    }
    .service-order .bold-content {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;
        font-size: 16px;
        line-height: 30px;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-order .title {
        font-size: 18px;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-order .select-box {
        text-align: center;
        margin: auto 0;
    }

    .service-order .foot-btn {
        padding: 10px 36px;
        font-size: 16px;
        margin: 0 20px;
        font-weight: 600;
        border-radius: 5px;
    }

    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .service-order >>> img {
        max-width: 100%;
        max-height: 100%;
    }

    .service-order >>> table {
        max-width: 100%;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
